import React, { useEffect, useState } from "react";
import HomeFrameImg from "assets/images/home/homeBannerImage.webp";
import HomeFrameMobImg from "assets/images/home/homeBannerMobImage.webp";
import AboutFrameImg from "assets/images/aboutUs/aboutusBanner.webp";
import { mediaBreakpoint } from "globals/utils/globalVariables";
import styles from "./EarlyYears.module.scss";
import { Container } from "semantic-ui-react";

const EarleyYears = ({ children, content }) => {
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    setwindowWidth(window.innerWidth);
  }, []);

  return (
    <>
      <div className={styles.headerImg}>
        {windowWidth > mediaBreakpoint.mobile ? (
          <Container>
            <img
              src={content === undefined ? HomeFrameImg : AboutFrameImg}
              className={styles.earlyYearsImg}
            />
          </Container>
        ) : (
          <img
            src={content === undefined ? HomeFrameMobImg : AboutFrameImg}
            className={styles.earlyYearsImg}
          />
        )}
      </div>
      <div>
        <div className={styles.header}>
          {content === undefined ? (
            <h2 className={styles.firstTitle}>SCHOOLING YEARS</h2>
          ) : (
            <>
              <h2 className={styles.firstTitle}>
                schoolage - The Catalyst School
              </h2>
              <h2 className={styles.secondTitle}>A New Era in Education</h2>
            </>
          )}
        </div>
        {content === undefined ? (
          <>
            <p className={styles.primaryContent}>
              Introducing SchoolAge, where learning is reimagined to span the
              realms of both the physical and virtual, creating a holistic
              educational experience that prepares students for the dynamic
              future of Earth 4.0. Our pedagogy masterfully integrates diverse
              educational philosophies with the power of AI and advanced
              technologies, crafting personalized learning journeys that evolve
              with each child. Through innovative programs like Virtual Ateliers
              and AI-driven co-tutoring systems, we create interactive and
              aesthetically enriching learning environments that foster
              creativity, critical thinking, and ethical reasoning. Our unique
              approach utilizes predictive analytics and advanced classification
              techniques to reveal and nurture the unique strengths and passions
              of each student. SchoolAge is designed not just to educate, but to
              unlock the full potential of every child, guiding them towards a
              future where their individuality and creativity can flourish.
            </p>
          </>
        ) : (
          <p className={styles.primaryContent}>
            At SchoolAge, we are dedicated to redefining education through the
            fusion of diverse educational philosophies, AI-driven
            personalization, and innovative, immersive learning environments.
            Our mission is to empower students and educators across various
            educational institutions, from elementary schools to high schools,
            by integrating advanced technologies and tailored learning
            experiences. We believe in bridging the gap between traditional
            education and the future demands of a rapidly evolving world,
            fostering lifelong learning, creativity, and critical thinking. By
            partnering with schools and educational institutions, we deliver
            scalable, seamless solutions that are set to transform the landscape
            of education for the 21st century and beyond.
          </p>
        )}
      </div>
      {children}
    </>
  );
};

export default EarleyYears;
